<template>
  <div class="common-container message-container">
    <div class="common-content">
      <div class="app-main">
        <div class="table-wrapper">
          <el-table
            v-loading="loading"
            border
            :data="tabData"
            style="width: 100%;height: 100%;"
            height="100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            />
            <el-table-column prop="type" label="消息类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.feedbackType && scope.row.feedbackType.typeName }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间" align="center">
              <template slot-scope="scope">
                {{
                  $formatDate(scope.row.createTime, "yyyy年MM月dd日 hh:mm:ss")
                }}
              </template>
            </el-table-column>
            <el-table-column prop="contact" label="状态" align="center">
              <template slot-scope="scope">
                <div class="flex-center status-box">
                  <div
                    class="flex-center status-txt"
                    :class="{
                      'status-pt': scope.row.status == '0'
                    }"
                  >
                    {{
                      scope.row.status == 0
                        ? "已完成"
                        : scope.row.status == 1
                        ? "未完成"
                        : scope.row.status == 2
                        ? "已关注"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="content"
              label="简介"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <div class="btn-box">
                  <el-button
                    size="mini"
                    style="margin-left:0px"
                    type="primary"
                    icon="el-icon-view"
                    @click="viewData(scope.row)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页组件-->
        <el-pagination
          :total="total"
          :current-page="page + 1"
          layout="total, prev, pager, next, sizes"
          @size-change="sizeChange"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { initDataMOD } from "@/mixins/initDataMOD";
export default {
  name: "messageIndex",
  components: {},
  mixins: [initDataMOD],
  data() {
    return {
      loading: false,
      data: [
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 2,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 2,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        },
        {
          type: "意见反馈",
          date: new Date(),
          status: 1,
          desc: `展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示展示简介信息，过长专家答疑专家答疑专家答疑专家答疑专家答疑……展示`
        }
      ],
      total: 10,
      page: 0,
      // 通用参数-start
      this_vue: this,
      url: this.$setApi("/cus/feedback/page"),
      tableIndex: 0, //选中的表格下标
      tabData: [],
      // 头部搜索配置参数
      queryOptions: [
        { key: "name", name: "厂商名称", value: "" },
        {
          key: "status",
          name: "状态",
          value: "",
          type: "select",
          statusList: [
            {
              id: 1,
              name: "在线"
            },
            {
              id: 2,
              name: "离线"
            }
          ]
        }
      ]
    };
  },
  created() {},
  computed: {},
  mounted() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  activated() {
    this.init();
  },
  methods: {
    viewData(item) {
      console.log("viewData", item);
      this.$router.push({
        name: "msgDetail",
        query: {
          id: item.id
        }
      });
    },
    // 初始化请求参数
    beforeInit() {
      this.sort = "id,desc"; // 支持数组多字段排序, 例: this.sort = ['id,desc', 'name,desc'];
      let pramasData = {};
      if (this.queryOptions.length > 0) {
        this.queryOptions.map(item => {
          if (item.value) pramasData[item.key] = item.value;
        });
      }
      this.params = {
        pageNum: this.page,
        pageSize: this.size,
        param: { ...pramasData }
      };
      this.request = { url: this.url, method: "post" };
      return true;
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.message-container
    .common-content
      position: relative
    .app-main
      position: absolute
      top 24px
      bottom 24px
      left 0%
      right 0
      padding 25px 97px
      overflow hidden
      background: #fff
      border-radius: 8px
      box-sizing: border-box
      .table-wrapper
        position:relative
        width:100%
        height: calc(100% - 48px)
        overflow: hidden
        box-sizing :border-box
        .el-table--small
            font-size 14px
        >>>.el-table .el-table__header-wrapper th.el-table__cell
            background #f6f6f6
        .status-box
          width 100%
          height 100%
        .msg-status
          padding 2px 8px
          background: #EEEEEE
          border-radius: 2px
          font-size 12px
          color #999
          font-weight 400
        .status-active
          color #3CC49D
          background: rgba(60,196,157,0.14)
</style>
