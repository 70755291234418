import request from "@/utils/request";
export function initData(url, params, config) {
  return request({
    url: url,
    method: "get",
    params: params,
    ...config
  });
}
export function postData(url, params, config) {
  return request({
    data: params,
    ...config
  });
}
