import { postData } from "./data";
import { del } from "@/api/index";
export const initDataMOD = {
  data() {
    return {
      loading: true,
      tableIndex: -1,
      tabData: [],
      page: 0,
      size: 10,
      total: 0,
      url: "",
      tabKey: 0,
      params: {},
      request: {},
      query: {},
      sort: "",
      time: 170,
      // 单删参数
      delUrl: "", //删除接口
      delMoreUrl: "",
      delLoading: false //删除lading
    };
  },
  computed: {},
  methods: {
    async init() {
      if (!(await this.beforeInit())) {
        return;
      }
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.params.pageNum += 1;
        postData(this.url, this.params, this.request)
          .then(res => {
            console.log("postData", res);
            this.parseData(res.data);
            setTimeout(() => {
              this.loading = false;
            }, this.time);
            resolve(res);
          })
          .catch(err => {
            this.loading = false;
            reject(err);
          });
      });
    },
    beforeInit() {
      return true;
    },
    // 赋值
    parseData(res) {
      this.total = res.total;
      setTimeout(() => {
        this.tabData = res.records;
      }, 300);
    },
    // 预防删除第二页最后一条数据时，或者多选删除第二页的数据时，页码错误导致请求无数据
    dleChangePage(size) {
      if (size === undefined) {
        size = 1;
      }
      if (this.tabData.length === size && this.page !== 0) {
        this.page = this.page - 1;
      }
    },
    // 多选择表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 设置page重新刷新
    changePage(page) {
      this.page = page;
      this.init();
    },
    // 切换上下页
    pageChange(e) {
      this.page = e - 1;
      this.init();
    },
    // 变化显示页数
    sizeChange(e) {
      this.page = 0;
      this.size = e;
      console.log("sizeChange", e);
      this.init();
    },
    // 单删表格数据
    submitDelete(id) {
      if (!this.$isDynamic) {
        this.delLoading = false;
        this.$refs[id].doClose();
        // this.tabData = this.tabData.filter(item => item.id !== id);
        let index = -1;
        this.tabData.some((item, idx) => {
          if (item.id == id) {
            index = idx;
            return true;
          }
        });
        this.tabData.splice(index, 1);
        this.$notify({
          title: "删除成功",
          type: "success",
          duration: 2500
        });
        return;
      }
      if (!this.delUrl) {
        this.$message.error("请填写删除方法！");
        return;
      }
      // return new Promise((resolve, reject) => {
      this.delLoading = true;
      del(this.delUrl, id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          // this.$message.error("系统错误，请联系管理员");
        });
    },
    // 获取选择的表格id
    getSelectIds() {
      var ids = "";
      if (this.multipleSelection == null || this.multipleSelection == "")
        return ids;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (ids == "") {
          ids = this.multipleSelection[i].id;
        } else {
          ids = ids + "," + this.multipleSelection[i].id;
        }
      }
      return ids;
    },
    // 头部批量删除
    deleteAll() {
      let ids = this.getSelectIds();
      if (ids == "") {
        this.$message.error("请选择要删除的记录！");
        return false;
      }
      this.$confirm("确定删除所选记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          del(this.$setApi(this.delMoreUrl) + ids).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.sizeChange();
          });
        })
        .catch(err => {
          // console.log("err", err);
          // this.$message.error("系统错误，请联系管理员");
        });
    },
    exportData() {
      let ids = this.getSelectIds();
      if (ids == "") {
        this.$message.error("请选择要导出的记录！");
        return false;
      }
      this.$message.warning("接口暂未提供");
    },
    // 关闭表格loadig
    formClose() {
      this.loading = false;
    },
    // 静态表单赋值
    setFormData(form, type = "add") {
      if (this.$isDynamic) return;
      console.log("setFormData", form, type);
      if (type == "add") this.tabData.push(form);
      else {
        this.tabData[this.tableIndex] = form;
        this.tabKey++;
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
